
import ResultSet from "@/models/ResultSet"
import WarningService from "@/services/WarningService"
import useTimeUtils from "@/utils/time"
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import LogsFilter from "./tools/LogsFilter.vue"
import router from "@/router/router"

export default {
  components: { LinkLabel, LogsFilter },
  setup() {
    let part = 0
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const scrollComponent = document.getElementsByClassName("p-datatable-wrapper")
    const lastError = ref(null)
    const loading = ref(true)
    const warningService = new WarningService()
    const items = ref([])
    const allItems = ref([])
    const selectedItems = ref([])
    let deleteDialog = ref(false)
    const filter = ref("")
    const { absoluteDateTime } = useTimeUtils()
    const windowWidth = ref(window.innerWidth)
    const scrollable = computed(() => windowWidth.value > 960)
    const showSortOptions = computed(() => windowWidth.value < 960)
    const activeFilters = reactive([])
    const allOrigins = reactive(new Set())

    const sortOptions = ref([
      { label: "Date ↓", value: "createdTime,asc" },
      { label: "Date ↑", value: "createdTime,desc" },
      { label: "Computer ↓", value: "computer.alias,asc" },
      { label: "Computer ↑", value: "computer.alias,desc" },
      { label: "Type ↓", value: "severity,asc" },
      { label: "Type ↑", value: "severity,desc" },
      { label: "Origin ↓", value: "origin,asc" },
      { label: "Origin ↑", value: "origin,desc" },
      { label: "Message ↓", value: "message,asc" },
      { label: "Message ↑", value: "message,desc" },
    ])
    const sort = ref(sortOptions.value[1].value)

    const comparators: any = reactive({
      equals: (a: string, b: any) => {
        if (Array.isArray(b) && b.length) {
          return b.includes(a)
        }
        return a === b
      },
      subString: (a: string, b: string) =>
        a ? a.toLowerCase().indexOf(b.toLowerCase()) !== -1 : null,
    })

    loadPart(0, sort.value, filter.value)
    watch(
      () => sort.value,
      () => {
        reload()
      }
    )
    onMounted(() => {
      loading.value = false
      if (scrollComponent.length > 0) {
        scrollComponent[0].addEventListener("scroll", checkVisible, { passive: true })
      }
      window.addEventListener("resize", checkVisible, { passive: true })
    })
    onUnmounted(() => {
      if (scrollComponent.length > 0) {
        scrollComponent[0].removeEventListener("scroll", checkVisible)
      }
      window.removeEventListener("resize", checkVisible)
    })
    function loadPart(part: Number, sort: any, filter: string) {
      loadingPart.value = true
      warningService.getWarnings(part, sort, filter).then((data) => {
        onPartLoaded(part, sort.value, data)
      })
    }
    function reload() {
      loadingPart.value = true
      part = 0
      loadedAllData.value = false
      items.value = []
      allItems.value = []
      loadPart(part, sort.value, filter.value)
    }
    const checkVisible = () => {
      if (loadedAllData.value) return
      if (loadingPart.value) return
      if (
        Math.ceil(scrollComponent[0].scrollTop * 1.3) >=
        scrollComponent[0].scrollHeight - scrollComponent[0].clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }
    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome
      if (result.rows.length == 0) {
        return
      }
      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        allItems.value.push(item)
        allOrigins.add(element.origin)
      })

      if (activeFilters.length) {
        items.value = allItems.value.filter(filterList)
      } else {
        items.value = allItems.value
      }

      nextTick().then(() => {
        checkVisible()
      })
    }
    function onPartFailed(error: any) {
      loadingPart.value = false
      lastError.value = error
    }
    function showDeleteDialog() {
      deleteDialog.value = true
    }
    function deleteWarning() {
      selectedItems.value.forEach((item) => {
        warningService.deleteWarning(item.data.id)
      })
      closeDialog()
      reload()
    }
    function closeDialog() {
      deleteDialog.value = false
    }
    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    const filterList = (row: any) => {
      return activeFilters
        .map((filter: any) => {
          return !!comparators[filter.type](filter.path(row.data), filter.compareValue)
        })
        .every((isTrue) => isTrue)
    }

    function openFujiPanelInfo(event: any) {
      if (event && event.stopPropagation) event.stopPropagation()
      window.open(" https://vsol.slab.com/posts/xc406pt2", "_blank")
    }

    const goToOrg = function (org: any) {
      router.push({ name: "organization", params: { organization_id: org.id } })
    }

    return {
      loading,
      loadedAllData,
      loadingPart,
      scrollComponent,
      items,
      selectedItems,
      lastError,
      deleteDialog,
      loadPart,
      reload,
      onPartLoaded,
      onPartFailed,
      absoluteDateTime,
      /*filter,
          clearFilter,*/
      sortColumn,
      showDeleteDialog,
      closeDialog,
      deleteWarning,
      scrollable,
      showSortOptions,
      sort,
      sortOptions,
      activeFilters,
      allOrigins,
      openFujiPanelInfo,
      goToOrg,
    }
  },
}

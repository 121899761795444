import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class WarningService {
  pageSize: number = 20

  getWarnings(part: Number = 0, sort: any, filter: string): Promise<ResultSet<any>> {
    let oThis = this
    return axios
      .get(
        "/computerlogs/support?size=" +
          this.pageSize +
          "&page=" +
          part +
          "&sort=" +
          sort +
          "&filter=" +
          filter,
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  deleteWarning(id: string) {
    return axios.delete("/computerlogs/" + id + "/support", {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }
}
